import { ref } from "vue";
import logFilterService from "@/services/application/logFilterService";
import reportPageService from "@/services/application/reportPageService";
const reportPageSvc = new reportPageService();
const reportPagesCookie = ref(new Array());
const logFilterSvc = new logFilterService();
import ReportContainer from "@/components/report/Core.vue";
export default {
    name: "ReportView",
    components: {
        ReportContainer
    },
    props: {
        isActiveReport: { type: Boolean, default: false },
        reportId: String,
        embeddedToken: String,
        bookmark: String,
        eventHandlers: { type: Map, default: new Map([["loaded", () => { }]]) },
        location: Object,
        product: Object,
        theme: Object,
        weeks: Object,
        date: String,
        isFirstReport: { type: Boolean, default: false },
        pageData: [Object],
        openPageIndex: { type: Number, default: undefined },
        pageName: { type: String, default: undefined },
        firstPage: { type: String, default: undefined },
        resetDateFilter: { type: Boolean, default: false },
        setDateFilter: { type: Boolean, default: false }
    },
    emits: {
        regionChanged: null,
        areaChanged: null,
        storeChanged: null,
        parentdepartmentChanged: null,
        departmentChanged: null,
        groupChanged: null,
        subgroupChanged: null,
        variantpluChanged: null,
        pageChanged: null,
        updateRecentReportsCookie: null
    },
    data() {
        return {
            reportLoaded: false,
            bookmarkLoaded: false,
            defaultTradingStatus: "All Trading Statuses",
            defaultRegion: "All Regions",
            defaultArea: "All Areas",
            defaultStore: "All Stores",
            defaultParentDepartment: "All Chambers",
            defaultDepartment: "All Product Departments",
            defaultGroup: "All Product Groups",
            defaultSubGroup: "All Product Sub Groups",
            defaultVariantPLU: "All Product Variant PLUs",
            reportPages: undefined,
            reportCurrentPage: undefined,
            eventHandler: new Map([
                ...this.eventHandlers,
                ["dataSelected", (dataSelected) => {
                        if (dataSelected.detail.dataPoints[0].identity[0]) {
                            this.getDataClick(dataSelected.detail.dataPoints[0].identity[0].equals, dataSelected.detail.dataPoints[0].identity[0].target);
                        }
                    }],
                ["pageChanged", async (event) => {
                        await this.until(_ => this.reportLoaded && this.reportCurrentPage && this.updateReportPageList());
                        if (this.isPageChanging || !this.reportLoaded || this.reportPages == undefined)
                            return;
                        this.updateReportPageList();
                        this.$emit("pageChanged", event.detail.newPage);
                        let category = this.$route.path.substring(1).toLowerCase();
                        let matchingReportPage = reportPagesCookie.value.find(reportPage => reportPage.category.toLowerCase() === category && reportPage.reportPageName.toLowerCase() === event.detail.newPage.displayName.toLowerCase());
                        if (matchingReportPage) {
                            let reportOpened = {
                                displayName: matchingReportPage?.displayName,
                                category: matchingReportPage?.category
                            };
                            this.$emit("updateRecentReportsCookie", reportOpened);
                        }
                        if (this.location != undefined && this.product != undefined) {
                            this.userPageChangedEvent(event.detail.newPage, this.location.hierarchyLevel, this.product.hierarchyLevel);
                        }
                    }],
                ["buttonClicked", (button) => {
                        if (button.detail.type == "WebUrl" && typeof (button.detail.title) !== "undefined") {
                            const buttonTitle = button.detail.title;
                            if (buttonTitle.startsWith("[")) {
                                const routePrefix = buttonTitle.substring(1, buttonTitle.indexOf("]"));
                                const bookmarkSuffix = buttonTitle.replace(buttonTitle.substring(0, buttonTitle.indexOf("]") + 2), "");
                                this.$router.push({ name: routePrefix, query: { bookmark: bookmarkSuffix } });
                            }
                            return;
                        }
                    }]
            ]),
        };
    },
    watch: {
        resetDateFilter: {
            handler: async function (resetDateFilter) {
                if (resetDateFilter) {
                    let newDateFilter = [await this.setFilter("Calendar", "Date", "All", [], 1, false)];
                    this.setReportFiltersForDate(newDateFilter);
                    let newWeekFilter = [await this.setAdvancedFilter("Calendar", "Retail Week", "And", "GreaterThanOrEqual", this.weeks.from, "LessThanOrEqual", this.weeks.to)];
                    this.setReportFiltersForWeek(newWeekFilter);
                }
            }
        },
        setDateFilter: {
            handler: async function (setDateFilter) {
                if (setDateFilter) {
                    let newDateFilter = [await this.setFilter("Calendar", "Date", "In", [this.date], 1, false)];
                    this.setReportFiltersForDate(newDateFilter);
                }
            }
        },
        firstPage: {
            handler: async function (firstPage) {
                if (firstPage != "true" || !this.reportPages || !this.isActiveReport)
                    return;
                await this.until(_ => this.reportLoaded && this.reportCurrentPage);
                await this.updateReportPageList();
                await this.setReportPageToOpen(this.reportPages[0]);
            }
        },
        pageName: {
            handler: async function (pageName) {
                if (!pageName || !this.isActiveReport)
                    return;
                await this.until(_ => this.reportLoaded && this.reportCurrentPage);
                await this.updateReportPageList();
                let reportPageToOpen = await this.reportPages.find((page) => page.displayName == pageName);
                if (!reportPageToOpen)
                    return;
                await this.setReportPageToOpen(reportPageToOpen);
            }
        },
        date: {
            handler: async function (newValue, oldValue) {
                if (!this.isActiveReport || newValue == undefined || await this.areObjectsEqual(newValue, oldValue))
                    return;
                await this.until(_ => this.reportLoaded && this.reportCurrentPage);
                let dateFilter = await this.generateReportFiltersForDate(this.reportCurrentPage, newValue);
                this.setReportFiltersForDate(dateFilter);
            },
            deep: true
        },
        weeks: {
            handler: async function (newValue, oldValue) {
                if (!this.isActiveReport || newValue == undefined || await this.areObjectsEqual(newValue, oldValue))
                    return;
                await this.until(_ => this.reportLoaded && this.reportCurrentPage);
                let weekFilters = await this.generateReportFiltersForWeek(this.reportCurrentPage, newValue.from, newValue.to, newValue.isCurrentWeek);
                await this.setReportFiltersForWeek(weekFilters);
            },
            deep: true
        },
        location: {
            handler: async function (newLocation, oldLocation) {
                if (!this.isActiveReport || newLocation == undefined || await this.areObjectsEqual(newLocation, oldLocation))
                    return;
                await this.until(_ => this.reportLoaded && this.reportCurrentPage);
                this.setReportLocationFilters(this.reportCurrentPage, newLocation);
                let reportPageToOpen = await this.getReportPageForHierarchy(newLocation.hierarchyLevel, this.reportCurrentPage);
                const updateLocationVisuals = this.setLocationVisualsForHierarchy(reportPageToOpen, newLocation.hierarchyLevel);
                const updateProductVisuals = this.setProductVisualsForHierarchy(reportPageToOpen, this.product.hierarchyLevel);
                await Promise.all([updateLocationVisuals, updateProductVisuals]);
                await this.setReportPageToOpen(reportPageToOpen);
            },
            deep: true
        },
        product: {
            handler: async function (newProduct, oldProduct) {
                if (!this.isActiveReport || newProduct == undefined || await this.areObjectsEqual(newProduct, oldProduct))
                    return;
                await this.until(_ => this.reportLoaded == true);
                this.setReportProductFilters(this.reportCurrentPage, newProduct);
                this.setProductVisualsForHierarchy(this.reportCurrentPage, newProduct.hierarchyLevel);
                this.isPageChanging = true;
                await this.$refs.reportContainer.clearAllSelections();
                this.isPageChanging = false;
            },
            deep: true
        },
        isActiveReport: {
            handler: async function (isActiveNow, wasActiveBefore) {
                if (isActiveNow && !wasActiveBefore) {
                    this.$refs.reportContainer.updateLoadingOverlay(true, false);
                    await this.until(_ => this.reportLoaded);
                    await this.updateReportPageList();
                    let bookmarkPresent = await this.goToBookmark(this.bookmark);
                    if (!bookmarkPresent && !this.pageName)
                        this.reportCurrentPage = this.reportPages[0];
                    if (this.pageName) {
                        let reportPageToOpen = await this.reportPages.find((page) => page.displayName == this.pageName);
                        this.reportCurrentPage = reportPageToOpen;
                    }
                    await this.setReportFilters(this.reportCurrentPage, this.weeks, this.location, this.product);
                    this.$emit("pageChanged", await this.reportPages.find((page) => page.isActive));
                    this.$refs.reportContainer.updateLoadingOverlay(true, true);
                }
            },
            deep: true
        },
        openPageIndex: {
            handler: async function (newPageIndex) {
                if (newPageIndex === undefined)
                    return;
                this.$refs.reportContainer.updateLoadingOverlay(true, false);
                let targetPage = this.reportPages[newPageIndex];
                await this.setReportPageToOpen(targetPage);
                if (this.location != undefined && this.product != undefined) {
                    await this.userPageChangedEvent(targetPage, this.location.hierarchyLevel, this.product.hierarchyLevel);
                }
                this.$refs.reportContainer.updateLoadingOverlay(true, true);
            },
            deep: true
        },
    },
    async mounted() {
        this.getReportPagesForCookies();
        this.$refs.reportContainer.updateLoadingOverlay(true, false);
        await this.until(_ => this.reportLoaded);
        await this.updateReportPageList();
        if (!this.isFirstReport) {
            let bookmarkPresent = await this.goToBookmark(this.bookmark);
            if (!bookmarkPresent && !this.pageName) {
                this.reportCurrentPage = this.reportPages[0];
            }
            if (this.pageName) {
                let reportPageToOpen = await this.reportPages.find((page) => page.displayName == this.pageName);
                this.reportCurrentPage = reportPageToOpen;
            }
            await this.setReportFilters(this.reportCurrentPage, this.weeks, this.location, this.product);
            this.$emit("pageChanged", await this.reportPages.find((page) => page.isActive));
            this.$refs.reportContainer.updateLoadingOverlay(true, true);
        }
    },
    methods: {
        async updateReportPageList() {
            this.reportPages = await this.$refs.reportContainer.getReportPages();
            this.reportCurrentPage = await this.reportPages.find((page) => page.isActive);
        },
        async goToBookmark(newBookmark) {
            this.$refs.reportContainer.updateLoadingOverlay(true, false);
            if (newBookmark === undefined)
                return false;
            await this.$refs.reportContainer.setBookmark(this.bookmark);
            await this.updateReportPageList();
            return true;
        },
        async areObjectsEqual(obj1, obj2) {
            if (obj1 == undefined && obj2 == undefined)
                return true;
            if (obj1 == undefined || obj2 == undefined)
                return false;
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
            if (keys1.length !== keys2.length)
                return false;
            for (const key of keys1) {
                if (obj1[key] !== obj2[key])
                    return false;
            }
            return true;
        },
        async setReportFilters(page, weeks, locations, products) {
            let reportFilters = [];
            let reportPageToOpen;
            if (weeks != undefined)
                reportFilters = await this.generateReportFiltersForWeek(page, weeks.from, weeks.to, weeks.isCurrentWeek);
            if (locations != undefined) {
                reportPageToOpen = await this.getReportPageForHierarchy(locations.hierarchyLevel, page);
                this.setLocationVisualsForHierarchy(reportPageToOpen, locations.hierarchyLevel);
                reportFilters = reportFilters.concat(await this.generateReportLocationFilters(reportPageToOpen, locations));
            }
            if (products != undefined) {
                this.setProductVisualsForHierarchy(this.reportCurrentPage, products.hierarchyLevel);
                reportFilters = reportFilters.concat(await this.generateReportProductFilters(page, products));
            }
            await this.applyReportFilters(reportFilters);
            if (reportPageToOpen)
                await this.setReportPageToOpen(reportPageToOpen);
        },
        async getReportPageForHierarchy(hierarchyLevel, page) {
            this.isPageChanging = true;
            let currentPageNoPrefix = page.displayName;
            if (page.displayName.startsWith("["))
                currentPageNoPrefix = page.displayName.replace(page.displayName.substring(0, page.displayName.indexOf("]") + 2), "");
            let reportPageToOpen;
            reportPageToOpen = this.reportPages.find((page) => page.displayName == "[" + hierarchyLevel + "] " + currentPageNoPrefix);
            if (typeof (reportPageToOpen) === "undefined")
                reportPageToOpen = this.reportPages.find((page) => page.displayName == currentPageNoPrefix);
            return reportPageToOpen;
        },
        async setReportPageToOpen(reportPageToOpen) {
            let category = this.$route.path.substring(1).toLowerCase();
            let matchingReportPage = reportPagesCookie.value.find(reportPage => reportPage.category.toLowerCase() === category && reportPage.reportPageName.toLowerCase() === reportPageToOpen.displayName.toLowerCase());
            if (matchingReportPage) {
                let reportOpened = {
                    displayName: matchingReportPage?.displayName,
                    category: matchingReportPage?.category
                };
                this.$emit("updateRecentReportsCookie", reportOpened);
            }
            this.isPageChanging = true;
            this.$emit("pageChanged", reportPageToOpen);
            await this.$refs.reportContainer.setReportPage(reportPageToOpen);
            await this.updateReportPageList();
            this.isPageChanging = false;
        },
        async setFilter(Table, Column, Operator, Values, Type, SingleSelect) {
            return {
                $schema: "http://powerbi.com/product/schema#basic",
                target: { table: Table, column: Column },
                operator: Operator,
                values: Values,
                filterType: Type,
                requireSingleSelection: SingleSelect
            };
        },
        async setAdvancedFilter(Table, Column, Operator, FirstCondition, FirstValue, SecondCondition, SecondValue) {
            return {
                $schema: "http://powerbi.com/product/schema#advanced",
                target: {
                    table: Table,
                    column: Column
                },
                logicalOperator: Operator,
                conditions: [
                    {
                        operator: FirstCondition,
                        value: FirstValue
                    },
                    {
                        operator: SecondCondition,
                        value: SecondValue
                    }
                ],
                filterType: 0
            };
        },
        async until(conditionFunction) {
            const poll = resolve => {
                if (conditionFunction())
                    resolve();
                else
                    setTimeout(_ => poll(resolve), 400);
            };
            return new Promise(poll);
        },
        async generateReportFiltersForDate(reportPage, date) {
            if (date == undefined)
                return;
            let filterOperator = "All";
            let filterValues = [""];
            let newFilter;
            await this.until(_ => this.pageData);
            let showDateFilter = await this.pageData.find(p => p.displayName == reportPage.displayName)?.showDateFilter;
            if (showDateFilter == undefined)
                showDateFilter = false;
            if (!showDateFilter) {
                newFilter = [await this.setFilter("Calendar", "Date", filterOperator, filterValues, 1, false)];
            }
            else {
                filterOperator = date === "clearDate" ? "All" : "In";
                filterValues = date === "clearDate" ? [] : [date];
                newFilter = [await this.setFilter("Calendar", "Date", filterOperator, filterValues, 1, false)];
            }
            return newFilter;
        },
        async setReportFiltersForDate(filter) {
            this.applyReportFilters(filter);
        },
        async generateReportFiltersForWeek(reportPage, fromWeek, toWeek, isCurrentWeek) {
            let newFilter;
            await this.until(_ => this.pageData);
            let showWeekFilter = await this.pageData.find(p => p.displayName == reportPage.displayName)?.showWeekFilter;
            if (showWeekFilter == undefined)
                showWeekFilter = true;
            if (!showWeekFilter) {
                newFilter = [await this.setFilter("Calendar", "Retail Week", "All", [], 1, true)];
            }
            else {
                newFilter = [await this.setAdvancedFilter("Calendar", "Retail Week", "And", "GreaterThanOrEqual", fromWeek, "LessThanOrEqual", toWeek)];
                if (isCurrentWeek)
                    newFilter.push(await this.setAdvancedFilter("Calendar", "Relative Date", "And", "LessThanOrEqual", 0, "LessThanOrEqual", 0));
                else
                    newFilter.push(await this.setFilter("Calendar", "Relative Date", "All", [], 1, true));
            }
            return newFilter;
        },
        async setReportFiltersForWeek(filter) {
            this.applyReportFilters(filter);
        },
        async setReportLocationFilters(reportPage, location) {
            const newFilter = await this.generateReportLocationFilters(reportPage, location);
            this.applyReportFilters(newFilter);
        },
        async generateReportLocationFilters(reportPage, location) {
            let filterOperator = "All";
            let filterValues = [""];
            await this.until(_ => this.pageData);
            let showLocationFilter = await this.pageData.find(p => p.displayName == reportPage.displayName)?.showLocationFilter;
            if (showLocationFilter == undefined)
                showLocationFilter = true;
            if (!showLocationFilter) {
                return [
                    await this.setFilter("Stores", "Is Trading", filterOperator, filterValues, 1, true),
                    await this.setFilter("Stores", "Region", filterOperator, filterValues, 1, true),
                    await this.setFilter("Stores", "Area", filterOperator, filterValues, 1, true),
                    await this.setFilter("Stores", "Store No And Name", filterOperator, filterValues, 1, true)
                ];
            }
            filterOperator = location.tradingStatus === this.defaultTradingStatus ? "All" : "In";
            filterValues = location.tradingStatus === this.defaultTradingStatus ? [] : [location.tradingStatus];
            let newFilter = [
                await this.setFilter("Stores", "Is Trading", filterOperator, filterValues, 1, true)
            ];
            filterOperator = location.region === this.defaultRegion ? "All" : "In";
            filterValues = location.region === this.defaultRegion ? [] : [location.region];
            newFilter.push(await this.setFilter("Stores", "Region", filterOperator, filterValues, 1, true));
            filterOperator = location.area === this.defaultArea ? "All" : "In";
            filterValues = location.area === this.defaultArea ? [] : [location.area];
            newFilter.push(await this.setFilter("Stores", "Area", filterOperator, filterValues, 1, true));
            filterOperator = location.store === this.defaultStore ? "All" : "In";
            filterValues = location.store === this.defaultStore ? [] : [location.store];
            newFilter.push(await this.setFilter("Stores", "Store No And Name", filterOperator, filterValues, 1, true));
            return newFilter;
        },
        async setReportProductFilters(reportPage, product) {
            const newFilter = await this.generateReportProductFilters(reportPage, product);
            this.applyReportFilters(newFilter);
        },
        async generateReportProductFilters(reportPage, product) {
            let filterOperator = "All";
            let filterValues = [""];
            await this.until(_ => this.pageData);
            let showProductFilter = await this.pageData.find(p => p.displayName == reportPage.displayName)?.showProductFilter;
            if (showProductFilter == undefined)
                showProductFilter = true;
            if (!showProductFilter) {
                return [
                    await this.setFilter("Products", "Product Department", filterOperator, filterValues, 1, true),
                    await this.setFilter("Products", "Product Sub-Department", filterOperator, filterValues, 1, true),
                    await this.setFilter("Products", "Product Group", filterOperator, filterValues, 1, true),
                    await this.setFilter("Products", "Product Sub-Group", filterOperator, filterValues, 1, true),
                    await this.setFilter("Products", "Product Variant", filterOperator, filterValues, 1, true)
                ];
            }
            filterOperator = product.parentDepartment === this.defaultParentDepartment ? "All" : "In";
            filterValues = product.parentDepartment === this.defaultParentDepartment ? [""] : [product.parentDepartment];
            let newFilter = [
                await this.setFilter("Products", "Product Department", filterOperator, filterValues, 1, true)
            ];
            filterOperator = product.department === this.defaultDepartment ? "All" : "In";
            filterValues = product.department === this.defaultDepartment ? [] : [product.department];
            newFilter.push(await this.setFilter("Products", "Product Sub-Department", filterOperator, filterValues, 1, true));
            filterOperator = product.group === this.defaultGroup ? "All" : "In";
            filterValues = product.group === this.defaultGroup ? [] : [product.group];
            newFilter.push(await this.setFilter("Products", "Product Group", filterOperator, filterValues, 1, true));
            filterOperator = product.subGroup === this.defaultSubGroup ? "All" : "In";
            filterValues = product.subGroup === this.defaultSubGroup ? [] : [product.subGroup];
            newFilter.push(await this.setFilter("Products", "Product Sub-Group", filterOperator, filterValues, 1, true));
            filterOperator = product.variantPLU === this.defaultVariantPLU ? "All" : "In";
            filterValues = product.variantPLU === this.defaultVariantPLU ? [] : [product.variantPLU];
            newFilter.push(await this.setFilter("Products", "Product Variant", filterOperator, filterValues, 1, true));
            return newFilter;
        },
        async applyReportFilters(filters) {
            try {
                logFilterSvc.logFilter(filters, this.$route.fullPath);
                const response = await this.$refs.reportContainer.setReportReplaceFilters(filters);
                return response;
            }
            catch (error) {
                console.error(error);
                return;
            }
        },
        async setDatafield(Table, Column) {
            return {
                $schema: "http://powerbi.com/product/schema#column",
                table: Table,
                column: Column
            };
        },
        async setProductVisualsForHierarchy(page, productHierarchyLevel) {
            const visuals = await this.$refs.reportContainer.getPageVisuals(page);
            for await (let visual of visuals) {
                let visualCapabilities = await visual.getCapabilities();
                if (typeof (visualCapabilities.dataRoles) == "undefined")
                    continue;
                for await (let dataRole of visualCapabilities.dataRoles) {
                    if (dataRole.name != "Category" && dataRole.name != "Rows")
                        continue;
                    let dataFields = await visual.getDataFields(dataRole.name);
                    if (typeof (dataFields[0]) == "undefined")
                        continue;
                    const columnsToInclude = [
                        "Product Department",
                        "Product Sub-Department",
                        "Product Group",
                        "Product Sub-Group",
                        "Product Variant"
                    ];
                    if (!columnsToInclude.includes(dataFields[0]["column"]))
                        continue;
                    let dataField;
                    if (dataFields[0]["table"] == "Products") {
                        switch (productHierarchyLevel) {
                            case "AllProducts":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Product Department");
                                break;
                            case "ProductLevel1":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Product Sub-Department");
                                break;
                            case "ProductLevel2":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Product Group");
                                break;
                            case "ProductLevel3":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Product Sub-Group");
                                break;
                            case "ProductLevel4":
                            case "ProductLevel6":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Product Variant");
                                break;
                        }
                        if (dataField && dataFields[0]["column"] != dataField.column)
                            this.$refs.reportContainer.setVisualReplaceField(visual, dataRole.name, 0, dataField);
                    }
                }
            }
        },
        async setLocationVisualsForHierarchy(page, locationHierarchyLevel) {
            const visuals = await this.$refs.reportContainer.getPageVisuals(page);
            for await (let visual of visuals) {
                let visualCapabilities = await visual.getCapabilities();
                if (typeof (visualCapabilities.dataRoles) == "undefined")
                    continue;
                for await (let dataRole of visualCapabilities.dataRoles) {
                    if (dataRole.name != "Category" && dataRole.name != "Rows")
                        continue;
                    let dataFields = await visual.getDataFields(dataRole.name);
                    if (typeof (dataFields[0]) == "undefined")
                        continue;
                    const columnsToInclude = ["Region", "Area", "Store No And Name"];
                    if (!columnsToInclude.includes(dataFields[0]["column"]))
                        continue;
                    let dataField;
                    if (dataFields[0]["table"] == "Stores") {
                        switch (locationHierarchyLevel) {
                            case "Company":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Region");
                                break;
                            case "Region":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Area");
                                break;
                            case "Area":
                            case "Store":
                                dataField = await this.setDatafield(dataFields[0]["table"], "Store No And Name");
                                break;
                        }
                    }
                    if (dataField && dataFields[0]["column"] != dataField.column)
                        this.$refs.reportContainer.setVisualReplaceField(visual, dataRole.name, 0, dataField);
                }
            }
        },
        async userPageChangedEvent(newPage, locationHierarchyLevel, productHierarchyLevel) {
            this.isPageChanging = true;
            this.$refs.reportContainer.updateLoadingOverlay(true);
            let currentPageNoPrefix = newPage.displayName;
            let newPageToOpen = newPage;
            if (newPage.displayName.startsWith("["))
                currentPageNoPrefix = newPage.displayName.replace(newPage.displayName.substring(0, newPage.displayName.indexOf("]") + 2), "");
            newPageToOpen = this.reportPages.find((page) => page.displayName == "[" + this.location.hierarchyLevel + "] " + currentPageNoPrefix && page.displayName != newPage.displayName) ?? newPage;
            if (newPageToOpen != this.reportPages[0])
                this.$router.push({ query: { firstPage: "false" } });
            if (newPageToOpen != newPage && newPageToOpen != undefined) {
                await this.setReportPageToOpen(newPageToOpen);
            }
            let weekFilters = await this.generateReportFiltersForWeek(newPage, this.weeks.from, this.weeks.to, this.weeks.isCurrentWeek);
            this.setReportFiltersForWeek(weekFilters);
            let dateFilters = await this.generateReportFiltersForDate(newPage, this.date);
            this.setReportFiltersForDate(dateFilters);
            const updateLocationVisuals = this.setLocationVisualsForHierarchy(newPageToOpen, locationHierarchyLevel);
            const updateProductVisuals = this.setProductVisualsForHierarchy(newPageToOpen, productHierarchyLevel);
            await Promise.all([updateLocationVisuals, updateProductVisuals]);
            this.isPageChanging = false;
        },
        async getDataClick(equals, target) {
            if (target.column == "Region")
                this.$emit("regionChanged", equals);
            if (target.column == "Area")
                this.$emit("areaChanged", equals);
            if (target.column == "Store No And Name")
                this.$emit("storeChanged", equals);
            if (target.column == "Product Department")
                this.$emit("parentdepartmentChanged", equals);
            if (target.column == "Product Sub-Department")
                this.$emit("departmentChanged", equals);
            if (target.column == "Product Group")
                this.$emit("groupChanged", equals);
            if (target.column == "Product Sub-Group")
                this.$emit("subgroupChanged", equals);
            if (target.column == "Product Variant")
                this.$emit("variantpluChanged", equals);
        },
        ReportRendered() {
        },
        ReportLoaded() {
            this.reportLoaded = true;
        },
        hasBookmarkLoaded() {
        },
        getReportPagesForCookies() {
            reportPageSvc.getReportPages()
                .then((result) => {
                reportPagesCookie.value = result.map(page => {
                    return page;
                });
            })
                .catch(reason => console.log(reason));
        }
    },
};
