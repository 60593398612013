import { apiFactory } from "@/api/factories/apiFactory";
import { useApplicationStore } from "@/stores/applicationStore";
import routerIndex from "@/router/index";
class applicationService {
    _applicationApi = apiFactory.useProfileApi();
    _applicationStore = useApplicationStore();
    _userApi = apiFactory.useUserApi();
    async initialiseApplication() {
        let allMenuItems = [
            {
                label: "Home",
                menuName: "Home",
                routeName: "Home",
                icon: "house"
            },
            {
                label: "KPIs",
                menuName: "KPIs",
                routeName: "KPIs",
                icon: "speedometer"
            },
            {
                label: "Sales",
                menuName: "SalesRoute",
                routeName: "SalesRoute",
                icon: "cart"
            },
            {
                label: "Costs",
                menuName: "CostsRoute",
                routeName: "CostsRoute",
                icon: "graph-down-arrow"
            },
            {
                label: "Inventory",
                menuName: "InventoryRoute",
                routeName: "InventoryRoute",
                icon: "box-seam"
            },
            {
                label: "People",
                menuName: "PeopleRoute",
                routeName: "PeopleRoute",
                icon: "people"
            },
            {
                label: "Fleet",
                menuName: "FleetRoute",
                routeName: "FleetRoute",
                icon: "truck"
            },
            {
                label: "Admin",
                menuName: "AdminRoute",
                icon: "shield-lock",
                children: [
                    {
                        label: "Notifications",
                        menuName: "Notifications",
                        routeName: "Maintain Notifications",
                        icon: "bell"
                    }
                ]
            }
        ];
        const profile = (await this._applicationApi.getProfile()).response;
        const user = await this._userApi.getUser();
        this._applicationStore.userDepartment = await this.getUserDepartment(user.nexusUserId);
        const routes = routerIndex.options.routes || [];
        allMenuItems = allMenuItems.filter(menuItem => {
            const route = routes.find(route => route.name == menuItem.menuName);
            const meetsPolicy = user?.meetsPolicies(route?.meta?.requiredPolicy);
            return meetsPolicy;
        });
        this._applicationStore.menuData = {
            menuItems: allMenuItems,
            profileDetails: profile
        };
    }
    updateBreadcrumbs(router) {
        if (router.currentRoute.value.meta.parentRouteName == undefined && router.currentRoute.value.name != "home") {
            this._applicationStore.breadcrumbPath = undefined;
            return;
        }
        const breadcrumbPath = this.buildBreadcrumbs(router, router.currentRoute.value.name?.toString());
        this._applicationStore.breadcrumbPath = breadcrumbPath;
        return;
    }
    buildBreadcrumbs(router, routeName) {
        const route = router.options.routes.find(item => item.name == routeName);
        const crumb = {
            label: route?.meta?.title,
            routeName: route?.name
        };
        let path = new Array(crumb);
        if (route?.meta?.parentRouteName != undefined) {
            path = [...this.buildBreadcrumbs(router, route?.meta?.parentRouteName), ...path];
        }
        return path;
    }
    async getUserDepartment(nexusUserId) {
        return await this._userApi.getUserDepartment(nexusUserId);
    }
}
export default applicationService;
